/** @jsx jsx */
import { jsx, Heading, Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import { Flex } from "@theme-ui/components"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import Listing from "@lekoarts/gatsby-theme-minimal-blog/src/components/listing"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"
import SEO from "@lekoarts/gatsby-theme-minimal-blog/src/components/seo"
import KnowledgeGraph from "./knowledge_graph"

type PostsProps = {
    posts: {
        slug: string
        title: string
        date: string
        excerpt: string
        description: string
        timeToRead?: number
        tags?: {
            name: string
            slug: string
        }[]
    }[]
    [key: string]: any
}

const Blog = ({ posts }: PostsProps) => {
    const { tagsPath, basePath } = useMinimalBlogConfig()

    return (
        <Layout>
            {/* <KnowledgeGraph posts={posts} maxWidth={800} maxHeight={300} /> */}
            {/* <Grid item xs={12} sm={4} >
                    <section sx={{ mb: [2, 2, 3], p: { fontSize: [1, 2, 3], mt: 1, }, variant: `section_hero` }}>
                        <Hero />
                    </section>
                </Grid>
                <Grid item xs={12} sm={8}>
                    
                </Grid> */}
            <SEO title=" Posts" />
                <Heading as="h1" variant="styles.h1" sx={{ marginY: 2 }}>
                    All Posts
                </Heading>
                <TLink
                    as={Link}
                    sx={{ variant: `links.secondary`, marginY: 2 }}
                    to={replaceSlashes(`/${basePath}/${tagsPath}`)}
                >
                    View all tags
                </TLink>
            <Listing posts={posts} sx={{ mt: [4, 5] }} showTags={true}  />
        </Layout>
    )
}

export default Blog





